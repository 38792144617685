import React from 'react';
import {PageColor} from '../components/page-color';
import {Content} from '../components/content';

export default class Services extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    get clients() {
        return this.props.pathContext.website.clients.split('\n').chunk(5);
    }

    render = () => (
        <main className="page--services">
            <PageColor color="white" nav="bordeaux"/>
            <section className="services">
                <div className="services__content">
                    <h1 className="service__title">
                        <div className="title-poppins">Our</div>
                        <div className="title-ivy">services</div>
                    </h1>
                    <Content document={this.props.pageContext.website.ourServices}/>
                    <div className="services__content__down">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                </div>
                <div className="services__image">
                    <div className="services__image__block"/>
                    <img src="/images/services/services.jpg"/>
                </div>
            </section>

            <div className="py-12 bg-white pb-48">
                <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                    <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
                        <li>
                            <div className="flex services__featured">
                                <div className="flex-shrink-0">
                                    <div
                                        className="flex items-center justify-center h-12 w-12 rounded-md text-white" style={{background: "#a5422b"}}>
                                        <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                             viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">Strategy
                                    </h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        We at Native Nation believe in personalised and well-thought-out campaign
                                        strategies. Based on your briefing we analyze the market and set meaningful
                                        goals in order to reach your target audience. We assess your results and
                                        optimize where needed. A full digital-first strategy is the result, ready to
                                        become the core of your next kick-ass campaign.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="mt-10 md:mt-0">
                            <div className="flex services__featured">
                                <div className="flex-shrink-0">
                                    <div
                                        className="flex items-center justify-center h-12 w-12 rounded-md text-white" style={{background: "#a5422b"}}>
                                        <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                             viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">Creation
                                    </h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        We create and curate impactful content: stories that make your thumb stop,
                                        experiences making you want to leave your house or just content making you
                                        wish you came up with it yourself. We know how to catch the attention anno
                                        2020: a world where everyone is self-declared creative, we stand out. We
                                        dive deep into your brand DNA and go beyond what you’ve seen!
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="mt-10 md:mt-0">
                            <div className="flex services__featured">
                                <div className="flex-shrink-0">
                                    <div
                                        className="flex items-center justify-center h-12 w-12 rounded-md text-white" style={{background: "#a5422b"}}>
                                        <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                             viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M13 10V3L4 14h7v7l9-11h-7z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">Production
                                    </h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        We got you covered! With a first-rate production team at our disposal, we
                                        deliver high-quality content at the speed of light. From copywriters and
                                        creative thinkers to graphic designers or even videographers: all keeping
                                        their skills up to date with the latest trends and innovations. Our team is
                                        ready to put its talents in your cup and tell your brand’s story in the most
                                        effective way.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="mt-10 md:mt-0">
                            <div className="flex services__featured">
                                <div className="flex-shrink-0">
                                    <div
                                        className="flex items-center justify-center h-12 w-12 rounded-md text-white" style={{background: "#a5422b"}}>
                                        <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                             viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">Distribution
                                    </h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        We don’t just create, we deliver: your message ànd results. The moment your
                                        content is ready to amaze the world, we start the next phase of your
                                        branding adventure. We spread the word by making use of our very own content
                                        platform (with over 2000 content creators connected), our own talent agency
                                        and paid media where we advertise, analyze and optimize. Outcome? Data and
                                        result-driven campaigns ready to predict your next move with us.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <section className="clients">
                <div className="clients__content">
                    <h1 className="service__title">
                        <div className="title-poppins">Our</div>
                        <div className="title-ivy">clients</div>
                    </h1>
                    <div className="clients__grid">
                        {this.clients.map(chunk => <div>{chunk.map(client => <div>{client}</div>)}</div>)}
                    </div>
                </div>
            </section>
        </main>
    );
}

Array.prototype.chunk = function (parts) {
    var array = this;
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
};